<template>
	<div class="ysmorderone">
		<div class="ordercon">
			<div class="order-step">
				<ul class="nav nav-pills nav-justified">
					
					<li :class="{active:$store.state.hxStepIndex==1}"><a href="javascript:void(0)" @click="go(1)">
								<span class="number">1</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">患者信息</span>
							</a></li>
						<li :class="{active:$store.state.hxStepIndex==2}"><a href="javascript:void(0)" @click="go(2)">
								<span class="number">2</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">治疗方案</span>
							</a></li>
						<li :class="{active:$store.state.hxStepIndex==3}"><a href="javascript:void(0)" @click="go(3)">
								<span class="number">3</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">牙位详图</span>
							</a></li>
						<li :class="{active:$store.state.hxStepIndex==4}"><a href="javascript:void(0)" @click="go(4)">
								<span class="number">4</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">上传X光片</span>
							</a></li>
						<li :class="{active:$store.state.hxStepIndex==5}"><a href="javascript:void(0)" @click="go(5)">
								<span class="number">5</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">上传口扫</span>
							</a></li>
						<li :class="{active:$store.state.hxStepIndex==6}"><a href="javascript:void(0)" @click="go(6)">
								<span class="number">6</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">上传口内照片</span>
							</a></li>
						<li :class="{active:$store.state.hxStepIndex==7}"><a href="javascript:void(0)" @click="go(7)">
								<span class="number">7</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">上传面相照片</span>
							</a></li>
						<!-- <li :class="{active:$store.state.hxStepIndex==8}"><a href="javascript:void(0)" @click="go(8)">
								<span class="number">8</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">留言</span>
							</a></li> -->
						<li :disabled="true" :class="{active:$store.state.hxStepIndex==9}"><a href="javascript:void(0)"
								@click="go(9)">
								<span class="number">8</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">校核订单</span>
							</a></li>
					<!-- 	<li disabled="disabled" :class="[{active:$store.state.ysmStepIndex==10}]"><a disabled="disabled"
								href="javascript:void(0)" @click="go(10)">
								<span class="number">10</span>
								<br />
								<span data-i18n="[html]forms.order.patient_information">完成</span>
							</a></li> -->
							<li  style="cursor: not-allowed;!important"  disabled="disabled" :class="[{active:$store.state.hxStepIndex==10}]">
								<a disabled="disabled" style="cursor: not-allowed;!important"
									href="javascript:void(0)" >
									<span class="number">9</span>
									<br />
									<span data-i18n="[html]forms.order.patient_information">提交订单</span>
								</a></li>
							<!-- 第十一步 -->
							<!-- <li v-if="elevenShow" :class="{active1:$store.state.hxStepIndex==11}"><a href="javascript:void(0)" @click="go(11)">
									<span class="number">10</span>
									<br />
									<span data-i18n="[html]forms.order.patient_information">管理员反馈</span>
								</a></li> -->
								<li v-if="elevenShow" class="active1"><a href="javascript:void(0)" @click="go(11)">
										<span class="number"></span>
										<br />
										<span data-i18n="[html]forms.order.patient_information">管理员反馈</span>
									</a></li>
				</ul>
			</div>
			<router-view></router-view>
		</div>
		<Footer></Footer>
	</div>

</template>

<script>
	import Footer from './ysm-footer.vue';
	export default {
		data: function() {
			return {
				elevenShow:false,
				a1:true,
				a2:false,
				a3:false,
				a4:false,
				a5:false,
				a6:false,
				a7:false,
				a8:false,
				a9:false,
				a10:false,
				a11:false
				
			}
		},
		components: {
			Footer
		},
		mounted:function(){
		    var zhuangtai=sessionStorage.getItem("zhaungtai");
			if(zhuangtai=="订单未提交"||zhuangtai==null){
				this.elevenShow=false;
			}else{
				this.elevenShow=true;
			}
		},
		methods:{
			go(num){
				console.log(num);
				this.$store.commit("setHxStepIndex",num);
				this.setOrderStep(num);
			},
			setOrderStep(num){
				this.$store.commit("setHxStepIndex",num);
				if(num==1){
					console.log(111);
					this.a1=true;
					this.a2=false;
					this.a3=false;
					this.a4=false;
					this.a5=false;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=false;
					this.$store.commit("setHxStepIndex",1);
					this.$router.push("hxorderone");
				}else if(num==2){
					this.a1=false;
					this.a2=true;
					this.a3=false;
					this.a4=false;
					this.a5=false;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=false;
					
					this.$store.commit("setHxStepIndex",2);
					this.$router.push("hxordertwo");
				}else if(num==3){
					this.a1=false;
					this.a2=false;
					this.a3=true;
					this.a4=false;
					this.a5=false;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=false;
					this.$store.commit("setHxStepIndex",3);
					this.$router.push("hxorderthree");
				}else if(num==4){
					this.a1=false;
					this.a2=false;
					this.a3=false;
					this.a4=true;
					this.a5=false;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=false;
					this.$store.commit("setHxStepIndex",4);
					this.$router.push("hxorderfour");
				}else if(num==5){
					this.a1=false;
					this.a2=false;
					this.a3=false;
					this.a4=false;
					this.a5=true;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=false;
					this.$store.commit("setHxStepIndex",5);
					this.$router.push("hxorderfive");
				}else if(num==6){
					this.a1=false;
					this.a2=false;
					this.a3=false;
					this.a4=false;
					this.a5=false;
					this.a6=true;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=false;
					this.$store.commit("setHxStepIndex",6);
					this.$router.push("hxordersix");
				}else if(num==7){
					this.a1=false;
					this.a2=false;
					this.a3=false;
					this.a4=false;
					this.a5=false;
					this.a6=false;
					this.a7=true;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=false;
					this.$store.commit("setHxStepIndex",7);
					this.$router.push("hxorderseven");
				}else if(num==8){
					// this.a1=false;
					// this.a2=false;
					// this.a3=false;
					// this.a4=false;
					// this.a5=false;
					// this.a6=false;
					// this.a7=false;
					// this.a8=true;
					// this.a9=false;
					// this.a10=false;
					// this.$store.commit("setHxStepIndex",8);
					// this.$router.push("hxordereight");
				}else if(num==9){
					this.a1=false;
					this.a2=false;
					this.a3=false;
					this.a4=false;
					this.a5=false;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=true;
					this.a10=false;
					this.a11=false;
					this.$store.commit('setHxStepIndex', 9);
					this.$router.push("hxordernine");
				}else if(num==10){
					this.a1=false;
					this.a2=false;
					this.a3=false;
					this.a4=false;
					this.a5=false;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=true;
					this.a11=false;
					this.$store.commit("setHxStepIndex", 10);
					this.$router.push("hxorderten");
				}else if(num==11){
					this.a1=false;
					this.a2=false;
					this.a3=false;
					this.a4=false;
					this.a5=false;
					this.a6=false;
					this.a7=false;
					this.a8=false;
					this.a9=false;
					this.a10=false;
					this.a11=true;
					this.$store.commit("setHxStepIndex", 11);
					this.$router.push("hxordereleven");
				}
			}
			
		},
		
		created:function(){
			let hxStepIndex=this.$store.state.hxStepIndex;
		}
	}
</script>

<style scoped="scoped">
	.ysmorderone {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		position: relative;
		margin-bottom: 1.875rem;
	}

	.ordercon {
		background-color: #FFFFFF;
		position: relative;
		width: 100%;
		/* min-height: 18.75rem;
		margin-top: 18.75rem; */
		margin-bottom: 18.75rem;
		/*  */
		min-height: 10rem;
		margin-top: 121px;
	}

	.order-step {
		width: 80%;
		margin: 0px auto;
		padding-left: 3.125rem;
		padding-right: 3.125rem;
		margin-top: 3.125rem;
		border: 1px solid white;
	}

	/*  */
	.nav-justified {
		width: 100%;
	}

	.nav {
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}

	.nav li {
		position: relative;
		background-color: #f0f0ee;
		border-left: 2px solid #fff;
		min-height: 4rem;
	}

	.nav li a {
		color: #333;
		padding: 12px 7px 15px 7px;
		min-height: 4rem;
		display: table-cell;
		vertical-align: middle;
	}

	.nav li.active a {
		color: #FFFFFF;
		background-color: #0D8EA4;


	}
	.nav li.active a .number{
		color: #FFFFFF;
	}
	.nav li.active1 a {
		color: #FFFFFF;
		background-color: #F18F57;
	
	
	}
	.nav li.active1 a .number{
		color: #FFFFFF;
	}

	.nav li a .number {
		color: #333;
		font-size: 24px;
		font-weight: 400;
	}

	@media (min-width:48rem) {
		.nav-justified>li {
			display: table-cell;
			width: 1%;
			vertical-align: middle;
		}

		.nav-justified>li>a {
			margin-bottom: 0;
			text-align: center;
		}

		.nav li a {
			display: block;
			position: relative;

		}
	}

	@media (min-width:62rem) {
		.nav-pills li a {
			word-break: break-word;
		}
	}
</style>
